<template>
  <page-container style="max-width: 900px;min-width: 0">
    <div class="title">Сведения об образовательной организации</div>
    <p class="p1">Сведения об ИП</p>
    <p class="p1">Полное наименование</p>
    <p class="p1">Индивидуальный предприниматель Суднеко Анна Михайловна</p>
    <p class="p1">ИНН 071307538652</p>
    <p class="p1">ОГРНИП 315504700018463</p>
    <p class="p1">Cокращенное наименование</p>
    <p class="p1">ИП Суднеко Анна Михайловна</p>
    <p class="p1">Дата регистрации.</p>
    <p class="p1">18.11.2015 г.</p>
    <p class="p1">Наименование представительств и филиалов образовательной организации</p>
    <p class="p1">Отсутствуют</p>
    <p class="p1">Место нахождения образовательной организации</p>
    <p class="p1">Г. Долгопрудный</p>
    <p class="p1">Режим и график работы</p>
    <p class="p1">понедельник - пятница с 10:00 — 19:00</p>
    <p class="p1">суббота, воскресенье - выходные</p>
    <p class="p1">Контактный телефон</p>
    <p class="p1">79261782525</p>
    <p class="p1">Адрес электронной почты</p>
    <p class="p2"><a href="mailto:ANNASUDNEKO@BK.RU"><span class="s1">ANNASUDNEKO@BK.RU</span></a></p>
    <p class="p1">адреса осуществления образовательной деятельности:</p>
    <p class="p1">Долгопрудный Новый Бульвар</p>
    <p class="p1">Программа:</p>
    <p class="p1">РЕАЛИЗУЕМЫЕ ОБРАЗОВАТЕЛЬНЫЕ ПРОГРАММЫ</p>
    <p class="p1">Дополнительная общеобразовательная программа - дополнительная общеразвивающая программа «ВЕДЕНИЕ ПСИХОЛОГИЧЕСКИХ ТРАНСФОРМАЦИОННЫХ ИГР»</p>
    <p class="p1">(направленность: социально-гуманитарная)</p>
    <p class="p1">Форма обучения: дистанционная, с применением исключительно электронного обучения, дистанционных образовательных программ</p>
    <p class="p1">Нормативный срок обучения: 24 академических часа.</p>
    <p class="p1">Срок реализации: 4 недели</p>
    <p class="p1">Уровень образования: Дополнительное образование детей и взрослых</p>
    <p class="p1">педагогические работники:</p>
    <p class="p1">Суднеко Анна Михайловна</p>
    <p class="p1">Перечень материально-технического оборудования поштучно:</p>
    <p class="p1">(СТОЛ – 1 ШТ, СТУЛ – 1 ШТ, компьютер, методические материалы</p>
    <p class="p1">Доступная среда:</p>
    <p class="p1">Образовательный центр ИП Суднеко А.М. не имеет специальных условий для обучения инвалидов и лиц с ограниченными возможностями здоровья</p>
    <p class="p1">Международное сотрудничество:</p>
    <p class="p1">Заключенные и планируемые к заключению договоры с иностранными и международными организациями по вопросам образования и науки отсутствуют.</p>
    <p class="p1">Документы: </p>
    <p class="p3" v-for="doc in docs" :key="doc"><a :href="doc.url" target="_blank">{{doc.name}}</a></p>
    <p class="p3"><a href="/dist/docs/Выписка_из_реестра_лицензий_№_Л035_01255_50_01056617_2.pdf" target="_blank">Выписка из реестра лицензий</a></p>
    <p class="p4">Структура и органы управления образовательной организацией:</p>
    <p class="p4">Индивидуальный предприниматель Суднеко Анна Михайловна</p>
    <p class="p5">&nbsp;</p>
    <p class="p4">Образование»:</p>
    <p class="p4">Индивидуальный предприниматель Суднеко Анна Михайловна осуществляет образовательную деятельность исключительно с использованием дистанционных образовательных технологий и электронного обучения, посредством электронной информационно-образовательной среды.</p>
    <p class="p5">&nbsp;</p>
    <p class="p4">Руководство. Педагогический (научно-педагогический) состав:</p>
    <p class="p4">Суднеко Анна Михайловна</p>
    <p class="p5">&nbsp;</p>
    <p class="p4">«Материально-техническое обеспечение и оснащенность образовательного процесса»:</p>
    <p class="p4">ноутбук – 1 шт, стол – 1 шт , стул 1 шт, методические материалы</p>
    <p class="p5">&nbsp;</p>
    <p class="p4">«Платные образовательные услуги»: В РАЗРАБОТКЕ</p>
    <p class="p4">«Финансово-хозяйственная деятельность»;</p>
  </page-container>
</template>

<script>

export default {
  name: "EduDocuments",
  data() {
    return {
      docs: [],
    }
  },
  methods: {},
  mounted() {
    this.$server.request('user/docs', {}, (data) => this.docs = data.response);
  }
}
</script>
<style lang="scss">

</style>
